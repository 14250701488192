<template>
  <div class="about">
    <h1>
      This is an about page
      <!-- {{ Number(gaugeValue).toFixed(2) }},
      {{ Number(angle).toFixed(2) }} -->
    </h1>

    <!-- <svg
      data-name="GaugeController"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 70"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="GaugeMask" class="cls-1" width="100" height="59" />
        </clipPath>
        <clipPath id="dash-path">
          <path
            class="cls-0"
            d="M81.15,58.11a32.25,32.25,0,1,0-62.3,0l-4.91.73a37.33,37.33,0,1,1,72.12,0Z"
          />
          <path
            class="cls-0"
            d="M92.47,49.77l4.35-.08V49.1l-4.36.15Zm-.16,4.68,4.34.38,0-.58-4.34-.32Zm-.19-9.34,4.31-.58L96.35,44,92,44.58Zm3.21-6.2L91.12,40l.12.5,4.23-1ZM93.76,34l-4.07,1.56.18.48L94,34.54Zm-2.11-4.7-3.87,2,.23.45,3.91-1.93ZM89,24.85l-3.61,2.4.27.43,3.67-2.34C89.25,25.17,89.14,25,89,24.85ZM86,20.71l-3.33,2.8.33.4,3.37-2.76Zm-3.5-3.77-3,3.14.39.38,3-3.13ZM75.87,17l.42.32L79,13.93l-.44-.35ZM72,14.38l.44.28L74.77,11l-.49-.29Zm-4.14-2.2.47.22,1.9-3.92-.51-.25Zm-4.33-1.73.49.16L65.48,6.5l-.55-.18ZM59,9.21l.51.1,1-4.24L60,5Zm-4.63-.74.53.06.48-4.33-.55-.07Zm-4.66-.23h.5l0-4.36h-.58Zm-5.17-4,.49,4.32.53,0-.47-4.34Zm-5.09.87,1,4.24.5-.1L40.06,5Zm-5,1.42L36,10.61l.5-.17-1.4-4.12Zm-4.76,2,1.88,3.93.48-.23L30.29,8.23ZM25.24,11l2.32,3.7.43-.27-2.25-3.74C25.58,10.74,25.42,10.85,25.24,11Zm-4.21,3,2.7,3.43.41-.33-2.66-3.45Zm-3.87,3.42,3.06,3.1.37-.37-3-3.13Zm-3.47,3.8,3.37,2.77.34-.42L14.06,20.7Zm-3,4.17,3.66,2.38.29-.45L11,24.82ZM8.09,29.78l3.9,2,.24-.47-3.88-2ZM6,34.52,10.13,36l.18-.5L6.25,34ZM4.53,39.45l4.23,1L8.9,40,4.67,38.91ZM8,44.58,3.66,44l-.09.58,4.32.56Zm-.31,9.51-4.34.34,0,.57,4.34-.4Zm-.12-4.34,0-.51L3.2,49.09l0,.59v.16l4.35.1Z"
          />
        </clipPath>

        <linearGradient
          id="linear-gradient-2"
          x1="12.67"
          y1="35.35"
          x2="87.33"
          y2="35.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            v-for="(stop, i) in gradient"
            :key="i"
            :offset="stop.offset"
            :stop-color="stop.color"
          />
        </linearGradient>
      </defs>

      <filter id="blur">
        <feGaussianBlur stdDeviation="0.5" />
      </filter>
      <circle
        v-if="debugMode"
        :cx="originX"
        :cy="originY"
        r="37"
        stroke="red"
        fill="transparent"
      />
      <circle
        id="GaugePin"
        :cx="originX"
        :cy="originY"
        r="1"
        fill="green"
        :opacity="debugMode ? 1 : 0"
      />


      <g class="cls-2">

        <path
          id="GaugeBg"
          class="cls-4"
          d="M81.15,58.11a32.25,32.25,0,1,0-62.3,0l-4.91.73a37.33,37.33,0,1,1,72.12,0Z"
        />


        <g id="GaugeColorDashes">
          <path
            class="cls-4"
            d="M92.47,49.77l4.35-.08V49.1l-4.36.15Zm-.16,4.68,4.34.38,0-.58-4.34-.32Zm-.19-9.34,4.31-.58L96.35,44,92,44.58Zm3.21-6.2L91.12,40l.12.5,4.23-1ZM93.76,34l-4.07,1.56.18.48L94,34.54Zm-2.11-4.7-3.87,2,.23.45,3.91-1.93ZM89,24.85l-3.61,2.4.27.43,3.67-2.34C89.25,25.17,89.14,25,89,24.85ZM86,20.71l-3.33,2.8.33.4,3.37-2.76Zm-3.5-3.77-3,3.14.39.38,3-3.13ZM75.87,17l.42.32L79,13.93l-.44-.35ZM72,14.38l.44.28L74.77,11l-.49-.29Zm-4.14-2.2.47.22,1.9-3.92-.51-.25Zm-4.33-1.73.49.16L65.48,6.5l-.55-.18ZM59,9.21l.51.1,1-4.24L60,5Zm-4.63-.74.53.06.48-4.33-.55-.07Zm-4.66-.23h.5l0-4.36h-.58Zm-5.17-4,.49,4.32.53,0-.47-4.34Zm-5.09.87,1,4.24.5-.1L40.06,5Zm-5,1.42L36,10.61l.5-.17-1.4-4.12Zm-4.76,2,1.88,3.93.48-.23L30.29,8.23ZM25.24,11l2.32,3.7.43-.27-2.25-3.74C25.58,10.74,25.42,10.85,25.24,11Zm-4.21,3,2.7,3.43.41-.33-2.66-3.45Zm-3.87,3.42,3.06,3.1.37-.37-3-3.13Zm-3.47,3.8,3.37,2.77.34-.42L14.06,20.7Zm-3,4.17,3.66,2.38.29-.45L11,24.82ZM8.09,29.78l3.9,2,.24-.47-3.88-2ZM6,34.52,10.13,36l.18-.5L6.25,34ZM4.53,39.45l4.23,1L8.9,40,4.67,38.91ZM8,44.58,3.66,44l-.09.58,4.32.56Zm-.31,9.51-4.34.34,0,.57,4.34-.4Zm-.12-4.34,0-.51L3.2,49.09l0,.59v.16l4.35.1Z"
          />
        </g>


        <g id="GaugeGreyDashes">
          <rect
            :width="originX"
            :height="originY"
            :fill="maskColor"
            :transform="`rotate(${angle},${originX},${originY})`"
          />
          <rect
            v-if="angle < 110 && angle > -90"
            :x="originX"
            :width="originX"
            :height="originY * 1.5"
            :fill="maskColor"
            :transform="`rotate(${angle},${originX},${originY})`"
          />
        </g>
      </g>
      <circle
        id="GaugePointerBlur"
        v-if="pointerShadow"
        r="5"
        filter="url(#blur)"
        fill="black"
        :opacity="pointerShadowOpacity"
        :transform="`translate(15,${originY}) rotate(${angle},${
          originX - 15
        },0)`"
      />
      <circle
        id="GaugePointer"
        r="4"
        :fill="pointerColor"
        :stroke-width="pointerBorderSize"
        :stroke="pointerBorderColor"
        :transform="`translate(15,${originY}) rotate(${angle},${
          originX - 15
        },0)`"
      />
    </svg> -->

    <v-container style="max-width: 400px">
      <v-row>
        <v-col align-self="center" class="text-center">
          <h1>{{ Number(dimmerValue).toFixed(1) }}</h1>
        </v-col>
        <v-col>
          <GaugeDimmer
            v-model="dimmerValue"
            :min="5"
            :max="35"
            style="max-height: 500px"
        /></v-col>
        <v-col align-self="center">
          <div>
            <v-btn text icon large @click="increase">
              <i class="fas fa-fw fa-2x fa-plus"></i>
            </v-btn>
          </div>
          <div class="mt-9">
            <v-btn text icon large @click="decrease">
              <i class="fas fa-fw fa-2x fa-minus"></i>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    {{ dimmerValue }}
  </div>
</template>

<script>
import GaugeDimmer from "@/components/touchable/molecular/Dimmer";

export default {
  components: {
    GaugeDimmer,
  },
  data() {
    return {
      dimmerValue: 20,
    };
  },
  mounted() {},
  methods: {
    increase() {
      this.dimmerValue++;
    },
    decrease() {
      this.dimmerValue--;
    },
  },
};
</script>

<style lang="scss">
</style>
